.security_level {
    width: 180px;
    height: 180px;

    .ant-progress-circle-gradient {
        width: 180px !important;
        height: 180px !important;
    }

    .ant-progress-inner {
        width: 180px !important;
        height: 180px !important;
    }
}

.ant-collapse-header {
    padding-left: 0px !important;
}

.ant-collapse-content>.ant-collapse-content-box {
    @apply pl-5;
}

.modal-border-none {
    .ant-modal-content{
        padding:20px 30px;
    }
    .ant-modal-header{
        border:none;
    }
    .ant-collapse-header{
        padding-bottom:0;
    }
    .ant-collapse-content-box{
        padding:0 !important; 
    }

}
@primary-color: #8E44AD;@text-color: var(--text-primary);