.ant-table-wrapper {
    height: 100%;

    .ant-spin-nested-loading {
        height: 100%;

        .ant-spin-container {
            height: 100%;
            display: flex;
            flex-direction: column;

            .ant-table {
                flex-grow: 1;

                .ant-table-container {
                    border-left: hidden;

                    table {
                        border-top: hidden;
                    }
                }
            }

            .ant-pagination {
                margin: 0.5rem;
            }
        }
    }
}

.android-tabs .ant-tabs-tabpane{
    @apply h-full overflow-y-auto;
}
@primary-color: #8E44AD;@text-color: var(--text-primary);