@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import './assets/styles/adc-common.less';
@import './assets/styles/animate.less';


html{
    font-family: 'Roboto', sans-serif;
}
iframe#webpack-dev-server-client-overlay{display:none!important}
.text-inkaorange {
  color: #F5812D;
}
p {
    margin-bottom: 0;
    padding-bottom: 0;
}

.ant-btn .anticon{
    padding-bottom: 0;
}

.page-content-tab{
    min-height: calc(100% - 71px);
    .ant-tabs-nav{
        margin-bottom:16px;
    }
}
::-webkit-scrollbar {
    width: 0.5rem
}

.account-info-form{
    .ant-form-item{
        .ant-form-item-label > label{
            font-weight:700;
            line-height: 24px;
        }
    }
}

.ant-descriptions-title{
    font-weight:500;
}

.hacking-attemps-by-country-list .ant-table-column-title {
    white-space: break-spaces;
}

/* HTML: <div class="loader"></div> */
.loader {
    display: flex;
    justify-content: space-between;
    width: 100px; /* Adjust to set spacing */
  }
  
  .loader .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #3c237c; /* Initial color */
    animation: colorChange 2s infinite ease-in-out, quickPulse 1s infinite linear;
  }
  
  @keyframes quickPulse {
    0%, 90% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.25);
    }
  }
  
  @keyframes colorChange {
    0% {
      background-color: #3c237c;
    }
    25% {
      background-color: #87348c;
    }
    50% {
      background-color: #e61d47;
    }
    75% {
      background-color: #87348c;
    }
    100% {
      background-color: #3c237c;
    }
  }
  
  .loader-wrap{
    position: absolute;
    background: rgba(0,0,0,0.5);
    z-index: 9999;
    display: flex;
    width: 100%;
    height:100vh;
    justify-content: center;
    align-items: center;
  }
  .loader {

    display: flex;
    justify-content: space-between;
    width: 100px; /* Adjust width based on spacing needs */
  }
  
  .loader .dot {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #3c237c; /* Initial color */
    animation: colorChange 2s infinite ease-in-out, quickPulse 1.5s infinite linear;
  }
  
  @keyframes quickPulse {
    0%, 75% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.25);
    }
  }
  
  @keyframes colorChange {
    0% {
      background-color: #3c237c;
    }
    25% {
      background-color: #87348c;
    }
    50% {
      background-color: #e61d47;
    }
    75% {
      background-color: #87348c;
    }
    100% {
      background-color: #3c237c;
    }
  }
  
  /* Adjusted delay for each dot to create a sequential scale animation */
  .loader .dot:nth-child(1) {
    animation-delay: 0.2s, 0s;
  }
  
  .loader .dot:nth-child(2) {
    animation-delay: 0.7s, 0.3s; /* Increased delay for slower pulse */
  }
  
  .loader .dot:nth-child(3) {
    animation-delay: 1.2s, 0.6s; /* Additional delay for slower sequence */
  }
  
  .loader .dot:nth-child(4) {
    animation-delay: 1.7s, 0.9s; /* Extended delay for slower pulse animation */
  }
  
  .loader .dot:nth-child(5) {
    animation-delay: 2.2s, 1.2s; /* Final dot with longer delay */
  }

  .MuiToolbar-root.MuiToolbar-gutters.MuiToolbar-regular.MuiTablePagination-toolbar {
    height:40px !important;
  }

  .MuiPickersMonth-monthButton.Mui-disabled{
    opacity: 0.5;
  }
@primary-color: #8E44AD;@text-color: var(--text-primary);