@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';


html {
  --text-accent: #8E44AD;
  --text-primary: #202124 !important;
  --bg-primary: #ffffff;
  --bg-secondary: #EFF2F5;
  --bg-tertiary: #ffffff;
}

.theme-light {
  --text-primary: #202124 !important;
  --bg-primary: #ffffff;
  --bg-secondary: #EFF2F5;
  --bg-tertiary: #ffffff;
}

.theme-dark {
  --text-primary: #ffffff !important;
  --bg-primary: #141414;
  --bg-secondary: #000000;
  --bg-tertiary: #1f1f1f;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size:14px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.ant-tabs-content {
  height: 100%;
}

.ant-table-tbody .ant-table-cell {
  white-space: nowrap;
}

.ant-table-tbody tr:nth-child(2n) td {
  background-color: #f8f8f8;
}

.form-item-required::before {
  display: inline-block;
  margin-right: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

::-webkit-scrollbar {
  width: 0.625rem;
  height: 0.625rem;
}

::-webkit-scrollbar-track {
  background: #f2f2f2;
  border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb {
  background: #d9d9d9;
  border-radius: 0.625rem;
}

::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

.chart-wrap-500 > div{
  min-height:500px;
}


  @media (max-width: 768px) {
    .chart-width-sm{
    max-width: calc(100vw - 20px);
    }
  }


  .hacking-attempt-table {
    .MuiDataGrid-columnSeparator.MuiDataGrid-columnSeparator--resizable.MuiDataGrid-columnSeparator--sideRight{
      display:none !important;
  }
  .MuiDataGrid-columnHeaderTitleContainer.MuiDataGrid-withBorderColor{
    border-bottom: none !important;
  }
  }