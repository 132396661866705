.ant-card-head {
    background-color: gray;
    color: white;
}

.ant-card-body {
    background-color: var(--bg-secondary);
}

.ios-card {
    display: flex;
    flex-direction: column;

    .ant-card-body {
        flex-grow: 1;
        @apply p-4 sm:p-6;
    }

    .ant-card-actions>li {
        @apply mt-2 mb-2 sm:mt-3 sm:mb-3;
    }
}

.ios-card-form-item {
    @apply flex gap-4 mt-6 items-center content-center flex-nowrap !important;

    .ant-form-item-label{
        @apply font-medium w-52 text-left flex-none !important;
    }
}

.app-card .ant-card-head{
    background-color: rgba(235, 222, 237, 0.5) !important;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
}

.ios-add-project{
    background: rgba(133, 72, 168, 1);
    color: white;
    border-radius: 8px;
    overflow: hidden;
    
    p{
        font-size:30px;
        padding:14px 25px;
        font-weight:500;
    }
}
@primary-color: #8E44AD;@text-color: var(--text-primary);