.appsealing-purple{
    color:#8548A8;
}

.appsealing-gray{
    color:#505050;
}

.appsealing-bg-purple{
    background-color: #8548A8;
}

 .custom-menu{
    padding:26px 14px;
    border-radius: 5px;
    box-shadow: none;
}

.badge-placeholder{
	min-width:8px;
	width:8px;
  height:8px;
	margin-top: 6px;
	margin-right:16px;
}

.unread-badge{
    border-radius: 50%;
		background:#8548A8;
}

.menu-read-badge{
  border-radius: 50%;
  background:#c2c2c2;
}

.custom-menu-item {
  white-space: nowrap; 
  overflow: hidden; 
  text-overflow: ellipsis; 
}

.custom-menu-item:hover {
  background: #8548A81A !important;
}

.notification-category-list .active{
  background: rgba(133, 72, 168, 0.1);
  color: #8548A8;
}
.read-btn{
  display: flex;
}

.read-btn button{
  display:none;
  background-color: rgba(97, 97, 97, 0.9);
}

.read-btn .read-radio{
  border-radius: 50%;
}
.notification-item:hover{
  background: rgba(133, 72, 168, 0.1);
  .read-btn button{
    display: block;
  }
  .read-radio{
    background: rgba(133, 72, 168, 0.2);
    border: 1px solid rgba(133, 72, 168, 0.3);
  }
}


@primary-color: #8E44AD;@text-color: var(--text-primary);