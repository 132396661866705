@media (max-width:640px){
    .ant-layout-sider{
        min-width: 100% !important;
    }
}

@media (max-width:640px){
    .mainview{
        max-height: calc(100vh - 5rem - 4px) !important;
    }
}

@media (min-width:640px){
    .mainview{
        max-height: calc(100vh - 4rem - 3px) !important;
    }
}

.ant-layout-sider-collapsed{
    @apply hidden sm:block;
}

.announcement-table {
    .ant-table-container {
        height: 100% !important;

        .ant-table-content {
            height: 100% !important;
            
            table {
                height: 100% !important;
            }
        }
    }
}

.announcement-timeline {
    .ant-timeline-item-label {
        width: calc(20% - 12px) !important;
    }

    .ant-timeline-item-tail {
        left: 20% !important;
    }

    .ant-timeline-item-head {
        left: 20% !important;
    }
    .ant-timeline-item-content {
        left: calc(20% - 4px) !important;
        width: calc(80% - 14px) !important;
    }
}

.ant-page-header-heading-extra{
    @apply flex items-center !important;
}

.ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    padding: 0 calc(50% - 20px / 2) !important;
}
.custom-menu {
  max-width: 367px; 
  overflow: hidden; 
}

.notification-list-head .ant-dropdown-menu-title-content {
    display:flex;
    justify-content: space-between;
}
.notification-list-head.ant-dropdown-menu-item:hover{
    background-color: transparent;
}

.billing-details .ant-tabs-tabpane{
    height:100%;
}

.profile-tabs .ant-tabs-tabpane.ant-tabs-tabpane-active{
    height:100%;
    overflow-y: auto;
}

.main-drawer .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .anticon{
    line-height: 0 !important;
}

.main-drawer .ant-menu-item-group {
   margin-bottom:8px;
}
.main-drawer .ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-title {
    display: none;
}
.main-drawer.ant-layout-sider-collapsed .ant-menu-inline-collapsed > .ant-menu-item-group {
    margin-bottom: 0;
}

.main-drawer .ant-menu.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item{
    padding: 0 calc(50% - 20px / 2);
    margin: 0 0;
    height:50px;
    line-height: 50px;
}

.main-drawer .g2-img {
    width:20px;
    height:20px;
    display: inline;
}
.main-drawer .ant-menu.ant-menu-inline-collapsed.ant-menu-vertical .ant-menu-item-selected::after{
    opacity:0;
}
.main-drawer .ant-menu-vertical .ant-menu-item-selected::after{
    opacity: 1;
    transform: scaleY(1);
    transition: transform .15s cubic-bezier(.645,.045,.355,1),opacity .15s cubic-bezier(.645,.045,.355,1);
}

.settings-btn .anticon-setting{
    padding-bottom: 0;
}

.absolute-footer{
    position: absolute;
    left:50%;
    bottom:0;
    transform: translateX(-50%);
}


.sider-submenu{
    height:calc(100vh - 56px);
    overflow: hidden;
    transition: 'width 0.3s ease-out';
    &.isOpen{
        width:220px;
    }
    &.isClose{
        width: 0;
    }
   a:hover{
        div{
            background-color: rgba(75, 75, 75, 0.06);
        }
   }

   .is-menu-active{
        a{
            div{
                background-color: rgba(133, 72, 168, 0.1);
            }
        }
    }
}

.sider-toggle{
    position: absolute;
    cursor: pointer;
   
    &.toggle-open{
        border-top: 1px solid rgba(224, 224, 224, 1);
        display: flex;
        justify-content: flex-end;
        left:56px;
        background-color:transparent;
        align-items: center;
        right:0;
        margin: 0 8px;
        bottom:0;
        width:100%;
        max-width:220px;
        height:53px;
        
    }
    &.toggle-close{
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        right:-24px;
        background-color: white;
        right:-24px;
        bottom:15px;
        height:24px;
        width:24px;
    }
}
@primary-color: #8E44AD;@text-color: var(--text-primary);