.slide-left {
    width: 100%;
    height: 100%;
    overflow: hidden;
}
  
.slide-left > * {
    animation: 3s slide-left 2s;
}

@keyframes slide-left {
    from {
      margin-left: 100%;
      width: 300%
    } 
    to {
      margin-left: 0%;
      width: 100%;
    }
}