.opt-google-popup{
	.ant-modal-header,
	.ant-modal-footer{
		border:none;
		.ant-btn-primary{
			width:120px;
			height:40px;
		}
	}
}
.hide-spinner{
		input::-webkit-outer-spin-button,
		input::-webkit-inner-spin-button {
			-webkit-appearance: none;
			margin: 0;
		}
}
@primary-color: #8E44AD;@text-color: var(--text-primary);