.feedback-popup{
	max-width:700px;

	&.ant-modal{
		width:80% !important;
	}
	.ant-modal-header{
		border-bottom:none;
		padding:40px;
	}
	.ant-modal-close{
		right:20px;
		top:20px;
	}
	.ant-modal-body{
		padding:20px 40px;
	}
	.ant-modal-footer{
		border-top:none;
		padding:40px;
	}
}
.feedback-button-wrap{
	position: fixed;
	right:20px;
	bottom:20px;
	.btn-pulse {
		height:40px;
		padding-left:22px;
		padding-right:8px;
		border-radius: 25px;
		line-height: 40px;
		text-align: center;
		margin:0;
		display: flex;
		justify-content: center;
		align-items: center;
		animation: pulse 1s ease-out 3; 
		font-weight:700;

	}
}


@keyframes pulse {
  0%, 100% {
    transform: scale(1);
    box-shadow: 0 0 0 0 rgba(142, 68, 173, 0.7);
  }
  50% {
    transform: scale(1.1); 
		box-shadow: 0 0 0 15px rgba(142, 68, 173, 0);
  }
}

@primary-color: #8E44AD;@text-color: var(--text-primary);