@keyframes loadmask-animation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.loadmask-wrap{
    width:100%;
    height:100%;
    overflow:hidden;
    position: fixed;
    left:0;
    top:0;
    z-index: 10007;
}
.loadmask {
    width: 100%;
    height: 100%;
    opacity: 0.5;
    z-index: 10007;
    position: relative;
    background-color: rgba(0,0,0,0.5);
}

.loadmask-item {
    top: 50%;
    left: 50%;
    z-index: 10009;
    position: absolute;
    text-align: center;
    transform: translate(-50%,-50%);
}

.loader-content {
    color: inherit;
    font-weight: 400;
    font-size: 1.5rem;
    margin-top: 0.5em;
}
.loader-image{
   margin-left:-20px;
}
.loader-image::before,
.loader-image::after {
    display: inline-block;
    content: "";
    border-radius: 50%;
    border-style: solid;
    border-top-color: transparent;
    border-bottom-color: transparent;
    border-width: max(1px, .015em);
    font-size: 4em;
}

.loader-image::before {
    margin-top: -.5em;
    width: 1em;
    height: 1em;
    animation: loadmask-animation .7s linear infinite;
}

.loader-image::after {
    margin-bottom: 1rem;
    margin-left: -.75em;
    width: .5em;
    height: .5em;
    animation: loadmask-animation reverse 1.4s linear infinite;
}
@primary-color: #8E44AD;@text-color: var(--text-primary);