.custom-input{
	position: relative;

	.custom-input-label{
		position: absolute;
		top:-8px;
		left:10px;
		padding: 0 4px;
		background-color: white;
		font-size:10px;
		color: rgba(0, 0, 0, 0.6);
	}
}
@primary-color: #8E44AD;@text-color: var(--text-primary);