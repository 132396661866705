.signupcontent>* {
    color: white;
    font-size: 1.1rem;
}

.signupcontent>h1 {
    font-size: 2.5rem;
    font-weight: 500;
}

.signupcontent>h2 {
    font-size: 1.5rem;
}

.signupcontent>p {
    font-weight: 300;
}

.signupcontent .icon {
    border: 1px solid rgba(245, 158, 11);
    background: rgba(245, 158, 11);
    padding: 0.25rem;
    border-radius: 50%;
    font-size: 1rem;
}
.agreement_all .ant-form-item-explain-error:nth-child(2){
    display: none;
}
.agreement.ant-form-item {
    margin-bottom: 0;
}

.agreement .ant-checkbox-wrapper-in-form-item{
    display:flex;
}
.agreement .ant-checkbox + span{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width:100%;
}
.gantar-feedback {
    font-size: 0.8rem !important;
    .ant-rate-star:not(:last-child) {
        margin-right: 2px !important;
    }
}
@primary-color: #8E44AD;@text-color: var(--text-primary);