.plan-table {
    @apply table text-center;

    .table-header-group {
        @apply font-medium text-2xl;
    }

    @media screen and (min-width: 768px) {
        .table-header-group>div {
            @apply table-cell p-2 w-1/5;
        }
    }


    .table-row-group>div {
        @apply table-cell;
        padding: 1.2rem
    }

    .table-row-group-item{
        display:flex;
        width:100%;
        justify-content: space-between;
        >div{
            padding:1.2em;
            width:25%;
        }
        .cell-1{
            width:35%;
            max-width: 320px;
        }
    }
    .table-row-group-item:nth-child(even) {
        background-color: #F9F9F9;
    }   

    .plan-table-tabs{
        width:31%;
        max-width:320px;
    }

    .plan-platform-tab{
        line-height: 48px;
        background-color: #A9A9A9;
        color:white;
        cursor:pointer;
        
        &.active{
            background-color: #F9F9F9;
            box-shadow: 4px -1px 4px -2px rgba(0, 0, 0, 0.15);
            color:var(--text-accent);
            font-weight:500;
            z-index: 1;
        }
    }

    @media screen and (max-width: 668px) {
       .plan-platform-tab{
            line-height:30px;
       }
        
    }

    
}
@primary-color: #8E44AD;@text-color: var(--text-primary);