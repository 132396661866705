@menu-icon-size: 1.5rem;
@table-row-hover-bg: fade(rgb(235,222,237), 50%);

.ant-card-head {
    padding: 0 1.7rem;

    .ant-card-head-title {
        padding: 1rem 0;
    }
}

.anticon {
    vertical-align: middle;
}

.ant-form-item {
    min-width: 13rem;
    margin-bottom: 1rem;

    .ant-form-item-label {
        padding: 0 0 4px;
        font-weight: 500;
    }

    .ant-picker {
        width: 100%;
    }

    .ant-input-number {
        width: 100%;
    }
}

.flex-form .ant-form-item {
    min-width: 16rem;
    @apply w-full sm:w-inherit;

    .ant-form-item-label{
        flex: none;
    } 
    .ant-form-item-control{
        flex: none;
    }
}

.ant-btn-secondary {
    color: var(--text-accent);
    border-color: var(--text-accent);
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-secondary:hover,
.ant-btn-secondary:focus {
    color: #fff;
    border-color: var(--text-accent);
    background-color: var(--text-accent);
}

.ant-btn-tertiary {
    color: #fff;
    border-color: #aaa;
    background: #aaa;
    text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
    box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-tertiary:hover,
.ant-btn-tertiary:focus {
    color: var(--text-primary);
    border-color: #d9d9d9;
}

.table-hide-selection .ant-table-selection-column {
    display: none;
}

.ant-table-cell {
    @apply truncate whitespace-nowrap;
}

.ant-upload-list-item-name {
    @apply font-semibold !important;
}

.ant-select-item-option-active {
    background-color: fade(rgb(235,222,237), 50%) !important;
}

.ant-upload.ant-upload-drag {
    border: 2px dashed #a7a3a8;
}